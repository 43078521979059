<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', settingsForm)">
        <b-row>
          <b-col md="6">
            <b-card>
              <b-row>
                <b-col md="12">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      mb-2
                    "
                  >
                    <div class="card-title mb-0">
                      Installment Deadline
                    </div>
                    <b-link
                      v-b-tooltip.hover.v-primary
                      :title="hotelonlyExample"
                    >
                      <strong> View Example</strong>
                    </b-link>
                  </div>
                </b-col>
                <b-col md="12">
                  <p class="">
                    <span>Second Installment deadline = </span>
                  </p>
                </b-col>
                <b-col md="12">
                  <p class="">
                    <span>Check-in/Departure Date - Cancellation Date </span>
                  </p>
                </b-col>
                <b-col md="12">
                  <b-form-group label-for="account-firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="Cancellation days"
                      rules="required|numeric|max:2|max_value:90"
                    >
                      <div class="d-flex align-items-center">
                        <label class="mr-2">Days</label>
                        <b-form-input
                          v-model="settingsForm.installement_deadline"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <p class="">
                    Installment threshold
                  </p>
                </b-col>
                <b-col md="12">
                  <b-form-group label-for="account-firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="Days"
                      rules="required|numeric|max:2|max_value:90"
                    >
                      <div class="d-flex align-items-center">
                        <label class="mr-2">Days</label>
                        <b-form-input
                          v-model="settingsForm.cancellation_deadline"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row>
                <b-col md="12">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      mb-2
                    "
                  >
                    <div class="card-title mb-0">
                      Advance Booking
                    </div>
                    <b-link
                      v-b-tooltip.hover.v-primary
                      :title="advanceBookingExample"
                    >
                      <strong>View Example</strong>
                    </b-link>
                  </div>
                </b-col>
                <b-col md="12">
                  <p>Booking available from = Current system date +</p>
                </b-col>
                <b-col md="12">
                  <b-form-group label-for="account-firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="Days"
                      rules="required|numeric|max:2|max_value:90"
                    >
                      <div class="d-flex align-items-center">
                        <label class="mr-2">Days</label>
                        <b-form-input
                          v-model="settingsForm.advance_booking_deadline"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-card>
              <b-row>
                <b-col md="12">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      mb-2
                    "
                  >
                    <div class="card-title mb-0">
                      Bank Transfers
                    </div>
                    <b-link
                      v-b-tooltip.hover.v-primary
                      :title="bankTransferExample"
                    >
                      <strong>View Example</strong>
                    </b-link>
                  </div>
                </b-col>
                <b-col md="12">
                  <p>
                    Bank Transfer available from = Booking/Current system date +
                  </p>
                </b-col>
                <b-col md="12">
                  <b-form-group label-for="account-firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="Days"
                      rules="required|numeric|max:2|max_value:90"
                    >
                      <div class="d-flex align-items-center">
                        <label class="mr-2">Days</label>
                        <b-form-input
                          v-model="settingsForm.bank_transfer_deadline"
                          :state="errors.length > 0 ? false : null"
                          name="firstname"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="align-items-end">
          <b-col cols="12" class="d-flex">
            <b-button
              variant="primary"
              class="editProfileBtn"
              type="submit"
              :disabled="loading"
            >
              Save changes
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BLink,
  VBTooltip,
  BButton
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, digits } from "@core/utils/validations/validations";

export default {
  components: {
    BCard,
    ValidationObserver,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    BLink,
    BButton
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
      required: true
    },
    settingDetails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hotelonlyExample:
        "Booking date = 10 Sep, 21, Check-in date =  6 Oct,21 , Installment deadline = 15 Sep, 21",
      bankTransferExample:
        "Example1: Booking date: 1 Aug, Departure date: 8 Aug: We do not offer Bank transfer option   Example2: Booking date: 1 Aug, Departure date: 12 Aug: We offer Card payment and Bank transfer payment option",
      advanceBookingExample:
        "Current Date = 1 Aug, Latest check-in/departure date = 5 Aug",
      loading: false,
      required,
      digits,
      items: [],
      settingsForm: {
        installement_deadline:
          Number(this.settingDetails[0].configuration.installement_deadline)
          || "",
        cancellation_deadline:
          Number(this.settingDetails[0].configuration.cancellation_deadline)
          || "",
        bank_transfer_deadline:
          Number(this.settingDetails[0].configuration.bank_transfer_deadline)
          || "",
        advance_booking_deadline:
          Number(
            this.settingDetails[0].configuration.advance_booking_deadline
          ) || ""
      }
    };
  },

  watch: {
    id: {
      handler(newValue) {
        switch (newValue) {
          case 1:
            this.settingsForm = this.settingDetails[0].configuration;
            break;
          case 2:
            this.settingsForm = this.settingDetails[1].configuration;
            break;
          case 3:
            this.settingsForm = this.settingDetails[2].configuration;
            break;
          default:
            this.settingsForm = "";
        }
      }
    }
  },

  methods: {
    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    }
  }
};
</script>
