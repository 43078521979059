<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <div v-for="(item, index) in settingDetails" :key="index">
      <b-tab @click="checkTab(item.id, index)">

        <!-- title -->
        <template #title>
          <feather-icon
            icon="HomeIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ item.name }}</span>
        </template>

        <settings-component :id="id" ref="simpleRules" :key="$route.fullPath" :setting-details="settingDetails" @on-Submit="editModule" />
      </b-tab>
    </div>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import errorResponseHandler from '@/services/errorHandler'
import moduleService from '@/services/module/module.service'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { resConditionCheck } from '@/helpers/constant'
// import checkLoginRole from '@/helpers/checkLoginRole'
import SettingsComponent from './SettingsComponent.vue'

export default {
  components: {
    BTabs,
    BTab,
    SettingsComponent
  },
  data() {
    return {
      id: 1,
      settingDetails: [],
      checkAccess: {},
      isLoaded: false,
      tabIndex: 0

    }
  },

  async mounted() {
    await this.getModuleListing()
  },

  methods: {
    checkTab(id, index) {
      this.id = id
      this.tabIndex = index
    },
    async getModuleListing() {
      try {
        const res = await moduleService.getModuleListing()
        if (resConditionCheck(res.status) && res.data.data) {
          this.settingDetails = res.data.data
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async editModule(settingsForm) {
      const valid = await this.$refs.simpleRules[this.tabIndex].validationForm()
      if (valid) {
        this.$refs.simpleRules[this.tabIndex].loading = true
        moduleService.editModule(this.id, settingsForm).then(res => {
          setTimeout(() => {
            this.$refs.simpleRules[this.tabIndex].loading = false
          }, 3000)
          if (resConditionCheck(res.status) && res.data.message) {
            const idx = this.settingDetails.findIndex(e => e.id === res.data.data.id)
            this.settingDetails[idx].configuration = res.data.data.configuration
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          setTimeout(() => {
            this.$refs.simpleRules[this.tabIndex].loading = false
          }, 3000)
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    },
  }
}
</script>
