var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.settingsForm)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                    mb-2\n                  "},[_c('div',{staticClass:"card-title mb-0"},[_vm._v(" Installment Deadline ")]),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":_vm.hotelonlyExample}},[_c('strong',[_vm._v(" View Example")])])],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('p',{},[_c('span',[_vm._v("Second Installment deadline = ")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('p',{},[_c('span',[_vm._v("Check-in/Departure Date - Cancellation Date ")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"account-firstname"}},[_c('validation-provider',{attrs:{"name":"Cancellation days","rules":"required|numeric|max:2|max_value:90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2"},[_vm._v("Days")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.settingsForm.installement_deadline),callback:function ($$v) {_vm.$set(_vm.settingsForm, "installement_deadline", $$v)},expression:"settingsForm.installement_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('p',{},[_vm._v(" Installment threshold ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"account-firstname"}},[_c('validation-provider',{attrs:{"name":"Days","rules":"required|numeric|max:2|max_value:90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2"},[_vm._v("Days")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.settingsForm.cancellation_deadline),callback:function ($$v) {_vm.$set(_vm.settingsForm, "cancellation_deadline", $$v)},expression:"settingsForm.cancellation_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                    mb-2\n                  "},[_c('div',{staticClass:"card-title mb-0"},[_vm._v(" Advance Booking ")]),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":_vm.advanceBookingExample}},[_c('strong',[_vm._v("View Example")])])],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('p',[_vm._v("Booking available from = Current system date +")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"account-firstname"}},[_c('validation-provider',{attrs:{"name":"Days","rules":"required|numeric|max:2|max_value:90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2"},[_vm._v("Days")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.settingsForm.advance_booking_deadline),callback:function ($$v) {_vm.$set(_vm.settingsForm, "advance_booking_deadline", $$v)},expression:"settingsForm.advance_booking_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                    mb-2\n                  "},[_c('div',{staticClass:"card-title mb-0"},[_vm._v(" Bank Transfers ")]),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":_vm.bankTransferExample}},[_c('strong',[_vm._v("View Example")])])],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('p',[_vm._v(" Bank Transfer available from = Booking/Current system date + ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"account-firstname"}},[_c('validation-provider',{attrs:{"name":"Days","rules":"required|numeric|max:2|max_value:90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2"},[_vm._v("Days")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"name":"firstname"},model:{value:(_vm.settingsForm.bank_transfer_deadline),callback:function ($$v) {_vm.$set(_vm.settingsForm, "bank_transfer_deadline", $$v)},expression:"settingsForm.bank_transfer_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"editProfileBtn",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[_vm._v(" Save changes "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }